import * as React from 'react'
import { graphql, Link, PageProps } from 'gatsby'
import { Layout } from '../components/Layout'
import { Seo } from '../components/Seo'
import { Image } from '../components/Image'
import dayjs from 'dayjs'
import { CallToAction } from '../components/Sections/CallToAction'
import { TagList } from '../components/TagList'
import { useState } from 'react'
import { TeacherCard } from '../components/TeacherCard'
import cn from 'classnames'
import { IconCard } from '../components/IconCard'
import ContactFormDialog from '../components/ContactFormDialog'
import { CourseDateBadge } from '../components/CourseDateBadge'

type CursosTemplateProps = PageProps<Queries.CursosQuery>

const CursosTemplate = ({
  data: {
    directus: { course, template, layout },
  },
}: CursosTemplateProps) => {
  if (!course || !template) {
    return null
  }

  if (!course?.title) {
    return null
  }

  const [contactIsOpen, setContactIsOpen] = useState(false)

  return (
    <Layout>
      <div className="container mb-14 space-y-14 xl:space-y-18">
        <div className="space-y-4 xl:space-y-0 mx-auto max-w-2xl xl:max-w-full xl:flex xl:space-x-8">
          <div className="xl:w-2/5">
            {course.featured_image && (
              <Image
                className="w-full"
                image={course.featured_image}
                alt=""
                objectFit="cover"
              />
            )}
          </div>
          <div className="xl:w-3/5">
            {!!course.types?.length && (
              <TagList
                tags={course.types.map((type) => {
                  return type?.course_types_id
                })}
              />
            )}
            <div className="mb-2.5">
              <h1 className="flex items-center text-2xl font-semibold text-brand-dark-blue">
                <CourseDateBadge
                  className="mr-1.5"
                  course={course}
                  finishedOnly={true}
                />
                <span>{course.title}</span>
              </h1>
              {course.subtitle && (
                <h2 className="text-lg font-medium text-gray-500">
                  {course.subtitle}
                </h2>
              )}
            </div>
            {course.venue && (
              <div className="mb-3">
                <span className="font-semibold text-brand-dark-blue">
                  {template.venue_heading}
                </span>{' '}
                {course.venue}
              </div>
            )}
            <div>
              <h3 className="text-2xl font-semibold text-brand-dark-blue">
                {template.description_heading}
              </h3>
              <div
                className="prose"
                dangerouslySetInnerHTML={{
                  __html: course.description || '',
                }}
              />
            </div>
          </div>
        </div>
        <div className="space-y-6 xl:space-y-0 mx-auto max-w-2xl xl:max-w-full xl:flex xl:justify-center xl:space-x-5">
          {template.date_heading && (
            <IconCard
              className="max-w-xl xl:w-1/4"
              icon={template.date_icon}
              heading={template.date_heading}
            >
              {dayjs(course.start_date).format('D [de] MMMM')} al{' '}
              {dayjs(course.end_date).format('LL')}
            </IconCard>
          )}
          {template.duration_heading && (
            <IconCard
              className="max-w-xl xl:w-1/4"
              icon={template.duration_icon}
              heading={template.duration_heading}
            >
              {course.duration}
            </IconCard>
          )}
          {template.pricing_heading && (
            <IconCard
              className="max-w-xl xl:w-1/2"
              icon={template.pricing_icon}
              heading={template.pricing_heading}
            >
              <div
                className="max-w-xl xl:mt-1 prose-basic"
                dangerouslySetInnerHTML={{
                  __html: course.cost || '',
                }}
              />
            </IconCard>
          )}
        </div>
      </div>
      {!!(course.speaker || course.tutor || course.locations?.length) && (
        <div className="mt-14 py-8 xl:py-10 bg-brand-light-gray">
          <div className="container flex flex-col justify-center xl:block">
            <h3 className="text-2xl font-semibold text-brand-dark-blue text-center">
              {template.details_heading}
            </h3>
            <div className="mt-8 xl:mt-10 space-y-8 md:space-y-0 md:flex md:space-x-5 lg:space-x-10">
              <div
                className={cn(
                  'flex flex-col items-center md:w-2/3',
                  course.speaker && course.tutor
                    ? 'justify-center'
                    : 'pb-2.5 md:pt-2 md:pb-5',
                )}
              >
                <div className="flex flex-col space-y-8 md:space-y-20">
                  {!!course.speaker && (
                    <TeacherCard
                      image={template.speaker_image}
                      heading={template.speaker_heading || ''}
                      teacher={course.speaker}
                    />
                  )}
                  {!!course.tutor && (
                    <TeacherCard
                      image={template.tutor_image}
                      heading={template.tutor_heading || ''}
                      teacher={course.tutor}
                    />
                  )}
                </div>
              </div>
              <div className="md:w-1/3 flex justify-center">
                {!!course.locations?.length && (
                  <div className="relative">
                    <Image
                      image={template.locations_image!}
                      alt={template.locations_heading || ''}
                    />
                    <div className="absolute top-8 left-3 right-1/2 pr-1 bottom-10">
                      <div className="font-semibold">
                        {template.locations_heading}
                      </div>
                      <div className="mt-1">
                        {course.locations.map((location, index) => (
                          <div
                            key={index}
                            className="inline-block py-1 px-1.5 font-semibold text-brand-light-blue bg-brand-light-gray my-1.5"
                          >
                            {location?.locations_id?.name}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {course.contact_banner?.type === 'banner' && (
        <CallToAction {...course.contact_banner}>
          <ContactFormDialog
            show={contactIsOpen}
            toggle={setContactIsOpen}
            header={
              <>
                <div className="flex space-between space-x-4 mb-4">
                  <div className="flex-grow">
                    <div className="mb-0.5 text-2xl font-bold text-brand-dark-blue">
                      {course.title}
                    </div>
                    <div className="text-gray-600 text-lg line-clamp-2">
                      {course.subtitle ||
                        course.short_description ||
                        course.description}
                    </div>
                  </div>
                </div>
                <h3 className="mb-2.5 text-xl font-semibold">
                  {course.contact_banner.title}
                </h3>
              </>
            }
            form={
              <>
                <input
                  type="hidden"
                  name="subjectPrefix"
                  value={`${course.contact_banner.title}`}
                />
                <input type="hidden" name="courseId" value={`${course.id}`} />
                <input type="hidden" name="courseTitle" value={course.title} />
                <input
                  type="hidden"
                  name="courseUri"
                  value={`/cursos/${course.slug}`}
                />
              </>
            }
            footer={
              <div className="mt-5 sm:-mt-12 sm:float-right text-gray-700">
                {course.contact_banner.description && (
                  <div
                    className="prose-basic text-sm"
                    dangerouslySetInnerHTML={{
                      __html: course.contact_banner.description,
                    }}
                  />
                )}
              </div>
            }
          />
          <Link
            className="Button --white"
            to="/contacto"
            onClick={(e) => {
              e.preventDefault()
              setContactIsOpen(true)
            }}
          >
            <div className="">{course.contact_banner.button_link?.label}</div>
          </Link>
        </CallToAction>
      )}
      {layout?.contact_banner && (
        <CallToAction className="pt-16" {...layout.contact_banner} />
      )}
    </Layout>
  )
}

export default CursosTemplate

export const Head = ({
  data: {
    directus: { course },
  },
}: CursosTemplateProps) => (
  <Seo
    title={course?.title}
    description={course?.meta_description || course?.short_description || ''}
  />
)

export const query = graphql`
  query Cursos($id: ID!) {
    directus {
      template: template_course {
        venue_heading
        description_heading
        date_icon {
          id
          imageFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        date_heading
        duration_icon {
          id
          imageFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        duration_heading
        pricing_icon {
          id
          imageFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        pricing_heading
        details_heading
        speaker_image {
          id
          imageFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        speaker_heading
        tutor_image {
          id
          imageFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        tutor_heading
        locations_heading
        locations_image {
          id
          imageFile {
            childImageSharp {
              gatsbyImageData(height: 540)
            }
          }
        }
      }
      layout {
        contact_banner {
          ...CallToActionComponent
        }
      }
      course: courses_by_id(id: $id) {
        id
        slug
        types {
          course_types_id {
            id
            name
            color
          }
        }
        title
        subtitle
        meta_description
        short_description
        featured_image {
          id
          imageFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        venue
        description
        start_date
        end_date
        duration
        cost
        speaker {
          ...TeacherCard
        }
        tutor {
          ...TeacherCard
        }
        locations {
          locations_id {
            name
          }
        }
        contact_banner {
          ...CallToActionComponent
        }
      }
    }
  }
`
