import * as React from 'react'
import { Image } from './Image'
import cn from 'classnames'

type IconCardProps = {
  className?: string
  icon?: Maybe<DirectusImage>
  heading: string
  children?: React.ReactNode
}

export const IconCard = ({
  className,
  icon,
  heading,
  children,
}: IconCardProps) => {
  return (
    <div className={cn(className, 'flex')}>
      <div className="mr-3 sm:mr-4 flex-shrink-0">
        <div>
          {icon && (
            <Image
              className="w-16 h-16 sm:w-20 sm:h-20"
              image={icon}
              alt=""
              objectFit="contain"
            />
          )}
        </div>
      </div>
      <div>
        <h4 className="text-xl font-semibold text-brand-light-blue">
          {heading}
        </h4>
        <div className="xl:mt-1">{children}</div>
      </div>
    </div>
  )
}
