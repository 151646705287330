import { graphql, useStaticQuery } from 'gatsby'
import * as React from 'react'
import { FloatingLabelInput } from './FloatingLabelInput'
import { FormEventHandler, useRef, useState } from 'react'
import cn from 'classnames'
import { CheckCircleIcon } from '@heroicons/react/solid'

const FORM_ENDPOINT = '/api/contact-us'

type ContactPayload = {
  name: string
  phone: string
  email: string
  municipality: string
  subject: string
  message: string
  [string: string]: string
}

type Errors = {
  name?: string
  phone?: string
  email?: string
  municipality?: string
  subject?: string
  message?: string
}

type ContactFormProps = {
  className?: string
  closeDialog?: () => void
  children?: React.ReactNode
}

const ContactForm = ({
  className,
  closeDialog,
  children,
}: ContactFormProps) => {
  const queryData: Queries.ContactPageTranslationsQuery =
    useStaticQuery(graphql`
      query ContactPageTranslations {
        directus {
          page_contacto {
            page_header {
              ...PageHeader
            }
            name_label
            phone_label
            email_label
            municipality_label
            subject_label
            message_label
            submit_text
            success_title_text
            success_message_text
          }
        }
      }
    `)

  const page = queryData.directus.page_contacto

  if (!page) {
    return null
  }

  const nameRef = useRef<HTMLInputElement>(null)
  const phoneRef = useRef<HTMLInputElement>(null)
  const emailRef = useRef<HTMLInputElement>(null)
  const subjectRef = useRef<HTMLInputElement>(null)
  const municipalityRef = useRef<HTMLInputElement>(null)
  const messageRef = useRef<HTMLInputElement>(null)

  const [submitted, setSubmitted] = useState(false)
  const [success, setSuccess] = useState(false)
  const [errors, setErrors] = useState<Errors>({})

  const submit = async function(data: ContactPayload) {
    const form = new FormData()
    for (const [name, value] of Object.entries(data)) {
      form.append(name, value)
    }

    const response = await fetch(FORM_ENDPOINT, {
      method: 'POST',
      body: form,
    })

    if (response.status === 204) {
      return true
    }

    if (response.status === 400) {
      return await response.json()
    }

    return response
  }

  const handleSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault()

    if (submitted) {
      return
    }

    const data: ContactPayload = {
      name: nameRef?.current?.value || '',
      phone: phoneRef?.current?.value || '',
      email: emailRef?.current?.value || '',
      municipality: municipalityRef?.current?.value || '',
      subject: subjectRef?.current?.value || '',
      message: messageRef?.current?.value || '',
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    for (const input of event.currentTarget.getElementsByTagName('input')) {
      if (input.type === 'hidden') {
        data[input.name] = input.value
      }
    }

    setSubmitted(true)
    setErrors({})

    submit(data).then((response) => {
      if (response === true) {
        setSuccess(true)

        return
      }

      if (response.errors) {
        setErrors(response.errors)
      } else {
        console.error(response)
        alert(`${response.status} - ${response.statusText}`)
      }

      setSubmitted(false)
    })
  }

  const Error = (name: string | null, message: string) => (
    <div className="px-0.5 pt-1 text-red-600 font-semibold text-sm">
      {message.replace('__LABEL_TOKEN__', name || '')}
    </div>
  )

  return (
    <div className={cn('relative', className)}>
      {success && (
        <div className="absolute px-16 w-full z-10 top-1/4">
          <div className="rounded-md bg-green-50 p-4">
            <div className="flex items-center">
              <div className="flex-shrink-0 self-start">
                <CheckCircleIcon
                  className="h-7 w-7 text-green-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3 flex-grow">
                <h3 className="font-semibold text-lg text-green-800">
                  {page.success_title_text}
                </h3>
                <div
                  className="mt-1 text-green-700"
                  dangerouslySetInnerHTML={{
                    __html: page.success_message_text || '',
                  }}
                />
              </div>
              {closeDialog && (
                <div className="px-5">
                  <div className="-mx-2 -my-1.5 flex">
                    <button
                      type="button"
                      className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                      onClick={closeDialog}
                    >
                      Cerrar
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <form
        onSubmit={handleSubmit}
        method="POST"
        className={cn('space-y-5', submitted && 'opacity-20')}
      >
        <div>
          {children}
          <FloatingLabelInput
            inputRef={nameRef}
            id="inputName"
            name="name"
            autoComplete="name"
            label={page.name_label}
            type="text"
            disabled={submitted}
            required
          />
          {errors?.name && Error(page.name_label, errors.name)}
        </div>
        <div>
          {children}
          <FloatingLabelInput
            inputRef={phoneRef}
            id="inputPhone"
            name="phone"
            autoComplete="phone"
            label={page.phone_label}
            type="text"
            disabled={submitted}
            required
          />
          {errors?.phone && Error(page.phone_label, errors.phone)}
        </div>
        <div>
          <FloatingLabelInput
            inputRef={emailRef}
            id="inputEmail"
            name="email"
            autoComplete="email"
            label={page.email_label}
            type="email"
            disabled={submitted}
            required
          />
          {errors?.email && Error(page.email_label, errors.email)}
        </div>
        <div>
          <FloatingLabelInput
            inputRef={municipalityRef}
            id="inputMunicipality"
            name="municipality"
            label={page.municipality_label}
            type="text"
            disabled={submitted}
          />
          {errors?.municipality &&
            Error(page.municipality_label, errors.municipality)}
        </div>
        <div>
          <FloatingLabelInput
            inputRef={subjectRef}
            id="inputSubject"
            name="subject"
            label={page.subject_label}
            type="text"
            disabled={submitted}
          />
          {errors?.subject && Error(page.subject_label, errors.subject)}
        </div>
        <div>
          <FloatingLabelInput
            inputRef={messageRef}
            id="inputMessage"
            name="message"
            label={page.message_label}
            type="textarea"
            disabled={submitted}
            rows={4}
            required
          />
          {errors?.message && Error(page.message_label, errors.message)}
        </div>
        <div>
          <button
            type="submit"
            name="submit"
            disabled={submitted}
            className="Button w-full justify-center font-semibold sm:justify-start sm:font-medium sm:w-auto"
          >
            {page.submit_text}
          </button>
        </div>
      </form>
    </div>
  )
}

export default ContactForm
