import * as React from 'react'
import cn from 'classnames'

type FloatingLabelInputProps = {
  label: string | null
  inputRef?: any
  className?: string
  inputClassName?: string
}

export const FloatingLabelInput = ({
  label,
  className,
  inputClassName,
  inputRef,
  ...props
}: FloatingLabelInputProps &
  Omit<React.HTMLProps<HTMLInputElement>, 'placeholder' | 'label'> &
  Omit<React.HTMLProps<HTMLTextAreaElement>, 'placeholder' | 'label'>) => {
  return (
    <div className={cn('floating-label relative', className)}>
      {props.type === 'textarea' ? (
        <textarea
          ref={inputRef}
          className={inputClassName}
          placeholder=" "
          {...props}
        ></textarea>
      ) : (
        <input
          ref={inputRef}
          className={inputClassName}
          placeholder=" "
          {...props}
        />
      )}
      <label htmlFor={props.id}>{label}</label>
      {props.required && (
        <span className="absolute top-0 right-1 text-red-400">*</span>
      )}
    </div>
  )
}
