import * as React from 'react'
import { graphql } from 'gatsby'
import { Image } from './Image'

type TeacherCardProps = {
  className?: string
  image: Maybe<DirectusImage>
  heading: string
  teacher: DirectusPerson
}

export const TeacherCard = ({
  className,
  image,
  heading,
  teacher,
}: TeacherCardProps) => {
  if (!teacher) {
    return null
  }

  return (
    <div className={className}>
      <div className="flex sm:items-center">
        <div className="mr-3 sm:mr-4 flex-shrink-0">
          <div className="rounded-full bg-white p-4 sm:p-6">
            {image && (
              <Image
                className="w-16 h-16 sm:w-24 sm:h-24"
                image={image}
                alt={heading}
              />
            )}
          </div>
        </div>
        <div>
          <h4 className="text-xl font-semibold text-brand-light-blue">
            {heading}
          </h4>
          <h5 className="mt-1 font-medium text-sm sm:text-base">
            {teacher.name}
          </h5>
          <div
            className="prose-basic text-sm sm:text-base"
            dangerouslySetInnerHTML={{
              __html: teacher.bio || '',
            }}
          />
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment TeacherCard on DirectusData_people {
    id
    name
    bio
  }
`
